<template>
  <modal-form
    v-slot="{ properties, errors, entity: e }"
    type="editRegister"
    :options="{
      properties: {
        name: '',
        store: null,
        registerLayout: null,
        sidebarMenu: null,
        receiptPrinter: null,
        preorderPrinter: null,
        customerScanners: [],
        theme: null,
        scale: null,
        paymentTerminal: null,
        invoiceIdentifier: null,
        language: 'de',
        orderCalloutNumberRangeStart: null,
        orderCalloutNumberRangeEnd: null,
        customerReceiptOptional: null,
        customerReceiptPrintMode: null,
        selfCheckout: null,
        allowPreorder: null,
      },
      postUri: '/registers',
      label: $t('register.label'),
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />

    <fancy-checkbox v-model="properties.selfCheckout" :label="$t('register.selfCheckout.label')" :error="errors.selfCheckout" />
    <fancy-checkbox v-model="properties.allowPreorder" :label="$t('register.allowPreorder.label')" :error="errors.allowPreorder" />
    <!--
    <fancy-checkbox v-model="properties.customerReceiptOptional" :label="$t('register.customerReceiptOptional.label')" :error="errors.customerReceiptOptional" />
    -->
    <simple-dropdown v-model="properties.customerReceiptPrintMode" :options="customerReceiptPrintModeOptions" :label="$t('register.customerReceiptPrintMode.label')" />
    <multiselect
      v-if="properties.selfCheckout"
      v-model="properties.registerLayout"
      :required="true"
      :options="async (q) => loadRegisterLayoutOptions(q, properties.registerLayout)"
      :error="errors.registerLayout"
      :filter-results="false"
      :form-label="$t('registerLayout.label')"
      searchable
      :loading="loadingRegisterLayoutOptions"
    />

    <multiselect
      v-if="!properties.selfCheckout"
      v-model="properties.sidebarMenu"
      :required="true"
      :options="async (q) => loadSidebarMenuOptions(q, properties.sidebarMenu)"
      :error="errors.sidebarMenu"
      :filter-results="false"
      :form-label="$t('sidebarMenu.label')"
      searchable
      :loading="loadingSidebarMenuOptions"
    />
    <multiselect
      v-model="properties.theme"
      :required="true"
      :options="async (q) => loadThemeOptions(q, properties.theme)"
      :error="errors.theme"
      :filter-results="false"
      :form-label="$t('registerTheme.label')"
      searchable
      :loading="loadingThemeOptions"
    />
    <multiselect
      v-model="properties.receiptPrinter"
      :required="true"
      :options="async (q) => loadPrinterOptions(q, properties.receiptPrinter, {store: properties.store})"
      :error="errors.receiptPrinter"
      :filter-results="false"
      :form-label="$t('printer.receipt.label')"
      searchable
      :loading="loadingPrinterOptions"
    />
    <multiselect
      v-model="properties.preorderPrinter"
      :required="false"
      :options="async (q) => loadPreorderPrinterOptions(q, properties.preorderPrinter, {store: properties.store})"
      :error="errors.preorderPrinter"
      :filter-results="false"
      :form-label="$t('printer.preorder.label')"
      searchable
      :loading="loadingPreorderPrinterOptions"
    />
    <multiselect
      v-model="properties.customerScanners"
      mode="tags"
      :options="async (q) => loadCustomerScannerOptions(q, properties.customerScanners, {store: properties.store})"
      :error="errors.customerScanners"
      :filter-results="false"
      :form-label="$t('customerScanner.label')"
      searchable
      :loading="loadingCustomerScannerOptions"
    >
      <template #option="{ option }">
        {{ option.deviceName }}
      </template>
      <template #tag="{ option, handleTagRemove }">
        <span v-if="option" class="multiselect-tag bg-primary">
          {{ option.deviceName }}
          <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
            <span class="multiselect-tag-remove-icon" />
          </span>
        </span>
      </template>
    </multiselect>
    <multiselect
      v-model="properties.scale"
      :options="async (q) => loadScaleOptions(q, properties.scale, {store: properties.store})"
      :error="errors.scale"
      :filter-results="false"
      :form-label="$t('scale.label')"
      searchable
      :loading="loadingScaleOptions"
    />
    <multiselect
      v-model="properties.paymentTerminal"
      :options="async (q) => loadPaymentTerminalOptions(q, properties.paymentTerminal, {store: properties.store})"
      :error="errors.paymentTerminal"
      :filter-results="false"
      :form-label="$t('paymentTerminal.label')"
      searchable
      :loading="loadingPaymentTerminalOptions"
    />
    <fancy-input
      v-model="properties.invoiceIdentifier"
      :disabled="!!e['@id']"
      type="number"
      :required="true"
      :label="$t('register.invoiceIdentifier.label')"
      :error="errors.invoiceIdentifier"
    />
    <multiselect
      v-model="properties.language"
      :options="languages"
      :error="errors.language"
      :required="true"
      :form-label="$t('general.field.language')"
      track-by="label"
      value-prop="value"
      label="label"
    />
    <fancy-input
      v-model="properties.orderCalloutNumberRangeStart"
      type="number"
      :label="$t('register.orderCalloutNumberRangeStart.label')"
      :error="errors.orderCalloutNumberRangeStart"
    />
    <fancy-input
      v-model="properties.orderCalloutNumberRangeEnd"
      type="number"
      :label="$t('register.orderCalloutNumberRangeEnd.label')"
      :error="errors.orderCalloutNumberRangeEnd"
    />

  </modal-form>
</template>

<script>
import { useI18n } from 'vue-i18n';
import useLoadOptions from '@/hooks/api/loadOptions';
import Modal from '@/components/utils/Modal.vue';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import FormButtons from '@/components/utils/forms/FormButtons.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';
import { CUSTOMER_RECEIPT_PRINT_MODE } from '@/constants';

export default {
  name: 'EditRegisterModal',
  components: {
    FancyCheckbox,
    Modal,
    ModalForm,
    FancyInput,
    Multiselect,
    FormButtons,
    SimpleDropdown,
  },
  emits: ['update'],
  setup(props) {
    const { t } = useI18n();
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');
    const {
      loadOptions: loadRegisterLayoutOptions,
      loading: loadingRegisterLayoutOptions,
    } = useLoadOptions('/register_layouts', 'name');
    const {
      loadOptions: loadSidebarMenuOptions,
      loading: loadingSidebarMenuOptions,
    } = useLoadOptions('/sidebar_menus', 'name');
    const {
      loadOptions: loadPrinterOptions,
      loading: loadingPrinterOptions,
    } = useLoadOptions('/printers', 'name');
    const {
      loadOptions: loadPreorderPrinterOptions,
      loading: loadingPreorderPrinterOptions,
    } = useLoadOptions('/printers', 'name');
    const {
      loadOptions: loadCustomerScannerOptions,
      loading: loadingCustomerScannerOptions,
    } = useLoadOptions('/customer_scanners', 'deviceName');
    const {
      loadOptions: loadScaleOptions,
      loading: loadingScaleOptions,
    } = useLoadOptions('/scales', 'name');
    const {
      loadOptions: loadPaymentTerminalOptions,
      loading: loadingPaymentTerminalOptions,
    } = useLoadOptions('/payment_terminals', 'name');
    const {
      loadOptions: loadThemeOptions,
      loading: loadingThemeOptions,
    } = useLoadOptions('/register_themes', 'name');
    const languages = [
      { key: 'de', label: t('general.languages.de'), value: 'de' },
      { key: 'en', label: t('general.languages.en'), value: 'en' },
    ];

    const customerReceiptPrintModeOptions = [];
    Object.entries(CUSTOMER_RECEIPT_PRINT_MODE).forEach((entry) => {
      const [key, value] = entry;
      customerReceiptPrintModeOptions.push({ key, label: t(`register.customerReceiptPrintMode.${key}`), value });
    });

    return {
      loadStoreOptions,
      loadingStoreOptions,
      loadRegisterLayoutOptions,
      loadingRegisterLayoutOptions,
      loadSidebarMenuOptions,
      loadingSidebarMenuOptions,
      loadPrinterOptions,
      loadingPrinterOptions,
      loadPreorderPrinterOptions,
      loadingPreorderPrinterOptions,
      loadCustomerScannerOptions,
      loadingCustomerScannerOptions,
      loadScaleOptions,
      loadingScaleOptions,
      loadPaymentTerminalOptions,
      loadingPaymentTerminalOptions,
      loadThemeOptions,
      loadingThemeOptions,
      languages,
      customerReceiptPrintModeOptions,
    };
  },
};
</script>
